<template>
  <div :class="{'soos': $store.state.utilizador1 ? $store.state.utilizador.empresa.afiliado === 'soos' : false}" style="background:#fff">
    <load-load></load-load>
    <div v-if="page === 'discussao' && page1 === 'email'">
      <router-view></router-view>
    </div>
    <div v-else>
      <div @click="SET_MENU_LOJA();SET_MENU_BANDEIRAS();SET_MENU_FLUTUANTE();SET_MENU_MOBILE();" class="height100 animation-duration-2 animation-fade" ref="capture" id="capture">

        <modal-suporte></modal-suporte>
        <modal-imprimir v-if="page === 'documentos' || page === 'cloudbeds'"></modal-imprimir>
        <modal-xml-at v-if="page === 'documentos'"></modal-xml-at>
        <modal-converter v-if="page === 'documentos' && $store.state.utilizador1"></modal-converter>
        <div v-if="$store.state.utilizador1"><modal-postits v-if="$store.state.utilizador.empresa.postit"></modal-postits></div>
        <div style="display: none;"><input type="text" id="PreventChromeAutocomplete" name="PreventChromeAutocomplete" autocomplete="address-level4" /></div>

        <!-- content site -->
        <div v-if="!$store.state.error" class="height100">

            <!-- template logado -->
            <div id="xsxsxs" v-if="$auth.check()" class="height100">
              <div v-if="page === 'pos'">
                <router-view></router-view>
              </div>
              <div v-if="page !== 'pos'" class="menuDirFlutuante" :style="$store.state.menuFlutuante ? 'position:fixed;right:270px;min-width:300px;': 'position:relative;right:0px;'">
                <!-- menu top -->
                <menu-top></menu-top>

                <div class="page pr" id="conteudo" v-if="$store.state.utilizador1 && !$store.state.menuMobile">
                  <menu-flutuante></menu-flutuante>

                  <div class="page-main pr padding-left-5">
                    <div class="alert alert-danger margin-vertical-10">
                    Até <b style="font-weight:bold;">31 de dezembro de 2025</b> são aceites faturas em ficheiro PDF, sendo consideradas como faturas eletrónicas para todos os efeitos previstos na legislação fiscal. /  Until <b style="font-weight:bold;">December 31, 2025</b>, invoices in PDF format are accepted and considered electronic invoices for all purposes under tax legislation.
                  </div>
                    <!--<page-certificado-ativarconta></page-certificado-ativarconta>-->
                    <!--<page-plano-caducar></page-plano-caducar>-->
                    <!--<page-titulo></page-titulo>-->
                    <!--plano caducar-->
                    <div class="alert alert-danger margin-vertical-10" v-if="$store.state.utilizador.empresa.afiliado === 'soos' && ($store.state.utilizador.plano.plano.nome === 'Gratuito' || $store.state.utilizador.plano.faltam < 30 && $store.state.utilizador.plano.faltam > 0 && $store.state.utilizador.plano.plano.nome !== 'Gratuito')">
                      O seu plano encontra-se a expirar ou expirou.<br />Para mais informações contacte-nos:<br />bill@soos.info<br />www.soos.info
                    </div>
                    <div v-else>
                      <div class="alert alert-danger margin-vertical-10" v-if="$store.state.utilizador.plano.faltam < 30 && $store.state.utilizador.plano.faltam > 0 && $store.state.utilizador.plano.plano.nome !== 'Gratuito'">
                        <span v-html="$t('plano_estar_a_caducar').replace('#date', $store.state.utilizador.plano.fim).replace('#days', $store.state.utilizador.plano.faltam)"></span>
                        <div class="text-right">
                          <router-link exact v-if="$store.state.page !== 'planos'" to="/planos" class="btn btn-danger btn-block-mobile">{{$t('renovar_plano')}}</router-link>
                        </div>
                      </div>
                      <div class="alert alert-danger margin-vertical-10" v-if="$store.state.utilizador.plano.plano.nome === 'Gratuito'">
                        {{$t('plano_caducou')}}
                        <div class="text-right">
                          <router-link exact v-if="$store.state.page !== 'planos'" to="/planos" class="btn btn-danger btn-block-mobile">{{$t('renovar_plano')}}</router-link>
                        </div>
                      </div>
                    </div>
                    <!--<div class="alert alert-info margin-vertical-10">
                      De acordo com a votação final da lei do OE de estado para 2024, as faturas em formato PDF continuam válidas, sendo consideradas como faturas eletrónicas para todas as finalidades fiscais até dia 31 Dezembro 2024.
                    </div>-->
                    <!--fim plano caducar-->
                    <!--nas guias o utilizador da at n ta configurado-->
                    <div v-if="page1 === 'guias' && !$store.state.utilizador.empresa.login_at_valido" class="alert alert-danger margin-vertical-10">
                      <svg width="24" aria-hidden="true" data-prefix="far" data-icon="sad-tear" class="margin-right-10 svg-inline--fa fa-sad-tear fa-w-16 text-danger" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm8-152c-13.2 0-24 10.8-24 24s10.8 24 24 24c23.8 0 46.3 10.5 61.6 28.8 8.1 9.8 23.2 11.9 33.8 3.1 10.2-8.5 11.6-23.6 3.1-33.8C330 320.8 294.1 304 256 304zm-88-64c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm-165.6 98.8C151 290.1 126 325.4 126 342.9c0 22.7 18.8 41.1 42 41.1s42-18.4 42-41.1c0-17.5-25-52.8-36.4-68.1-2.8-3.7-8.4-3.7-11.2 0z"></path></svg>
                      <router-link class="text-danger margin-left-10" to="/configuracoes/at"><b>{{$t('documentos.login_at_valido_erro')}}</b></router-link>
                    </div>
                    <!-- nao suporta compras -->
                    <div v-if="$store.state.utilizador.plano.plano.modulo_stock < 1 && page1 == 'compras'" class="alert alert-warning margin-vertical-10">
                      <svg width="24" aria-hidden="true" data-prefix="far" data-icon="sad-tear" class="margin-right-10 text-danger svg-inline--fa fa-sad-tear fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm8-152c-13.2 0-24 10.8-24 24s10.8 24 24 24c23.8 0 46.3 10.5 61.6 28.8 8.1 9.8 23.2 11.9 33.8 3.1 10.2-8.5 11.6-23.6 3.1-33.8C330 320.8 294.1 304 256 304zm-88-64c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm-165.6 98.8C151 290.1 126 325.4 126 342.9c0 22.7 18.8 41.1 42 41.1s42-18.4 42-41.1c0-17.5-25-52.8-36.4-68.1-2.8-3.7-8.4-3.7-11.2 0z"></path></svg> {{$t('nao_suporta_compras')}}
                      <div class="text-center" v-if="$store.state.utilizador.empresa.afiliado !== 'soos'"><router-link class="btn btn-primary" to="/planos">{{$t('documentos.planos')}}</router-link></div>
                      <div class="clearfix"></div>
                    </div>
                    <!--titulo paginas-->
                    <div class="page-header padding-horizontal-0" v-if="$store.state.titulo">
                      <h1 class="page-title" id="titulo1"><i class="glyphicon glyphicon-warning-sign margin-right-10" v-if="$store.state.page == 'notfound'"></i><span v-html="$store.state.titulo"></span>
                      </h1>
                    </div>
                    <!--fim titulo paginas-->

                    <div class="page-content" id="page-content">
                      <div>
                        <div class="pr">
                          <router-view></router-view>
                          <div class="margin-bottom-10 clearfix"></div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div v-if="page === 'ativar-conta'">
              <router-view></router-view>
            </div>
            <page-login v-else-if="!$auth.check()"></page-login>
        </div>

        <page-error v-if="$store.state.error"></page-error>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import load from './tpl/load'
import pageError from './tpl/page-error'
import pageLogin from './tpl/page-login'
import menuTop from './tpl/menu-top'
import menuFlutuante from './tpl/menu-flutuante'
// import pageCertificadoAtivarconta from './tpl/certificado-ativarconta'
// import pagePlanoCaducar from './tpl/plano-caducar'
// import pageTitulo from './tpl/titulo'
import modalSuporte from './tpl/modal/suporte'
import modalPostits from './tpl/modal/postits'
import modalXmlAt from './tpl/modal/xml-at'
import modalImprimir from './tpl/modal/imprimir'
import modalConverter from './tpl/modal/converter'

export default {
  components: { loadLoad: load, pageError, pageLogin, menuTop, menuFlutuante, modalSuporte, modalXmlAt, modalImprimir, modalPostits, modalConverter },
  mounted () {
    this.SET_PAGE()
    if (window.location === 'app.bill.pt') {
      window.addEventListener('error', (event) => {
        // console.log(event)
        this.axios.post('/error-log', { href: window.location.href, erro: encodeURI(event) }).then(() => {}, () => {})
        /*
        html2canvas(document.getElementById('capture')).then(canvas => {
          this.axios.post('/error-log', {img: canvas.toDataURL(), href: window.location.href, erro: encodeURI(err)}).then((res) => {
          }, (er) => {})
        }).catch((error) => {
          // console.log("Erorr descargando reporte visual", error)
        })
        */
      })
    }
    /*
    if (window.localStorage.getItem('versao') !== version) {
      window.localStorage.setItem('versao', version)
      window.location.reload()
    }
    */
    console.log('15-01-2025 v: 0.20.0')
    if (window.localStorage.getItem('bloquear') > 0) {
      this.$router.replace('/pos', () => {})
    }
    this.userTime()
  },
  watch: {
    '$route' () {
      window.scrollTo(0, 0)
      this.SET_LOAD(true)
      this.SET_PAGE()
    }
    /* '$auth.check' () {
      this.$router.replace('/', () => {})
    } */
  },
  computed: {
    ...mapState({
      page: 'page',
      page1: 'page1'
    })
  },
  methods: {
    ...mapMutations([
      'SET_MENU_LOJA',
      'SET_MENU_BANDEIRAS',
      'SET_MENU_FLUTUANTE',
      'SET_MENU_MOBILE',
      'SET_PAGE',
      'SET_LOAD',
      'GET_INFO',
      'SET_INFO_RESET'
    ]),
    userTime () {
      this._.delay((self) => {
        if (self.$auth.check()) {
          self.GET_INFO('utilizadorX')
          self.userTime()
        }
      }, 600000, this)
    }
  }
}
</script>
